
import { User } from "@/store/user/types";
import { Component, Vue } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { Timestamp, collection, doc, getDoc, getDocs, query, where, addDoc } from "firebase/firestore";
import { firestore } from "@/firebase";
import { startOfDay, endOfDay } from 'date-fns';

@Component({
    components: {}
})
export default class Home extends Vue {

    loading = false

    mounted() {
        this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Data', target: '/admin/data' }])
    }

    get user(): User {
        return this.$store.getters[USER_GETTERS.ACTING_USER]
    }

    async submit() {
        this.loading = true
        await addDoc(collection(firestore, 'data-export-requests'), {
            mailTo: this.user.email,
        })
        this.loading = false
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Data export requested'
        })
    }
}

